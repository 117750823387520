import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'


import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import '../theme/index.css'

import global from '@/global'

import * as VueGoogleMaps from 'vue2-google-maps'
import './assets/iconfont/iconfont.css'


  
Vue.prototype.GLOBAL = global
import * as math from 'mathjs';

Vue.prototype.$math = math
Vue.use(ElementUI);

import { VueJsonp } from 'vue-jsonp'
Vue.use(VueJsonp)

import Vue2Editor from "vue2-editor";

Vue.use(Vue2Editor);

import * as api from '@/request/api.js'
Vue.prototype.$api = api


import bottom from "@/components/bottom";

Vue.use(bottom);
import FileUpload from "@/components/FileUpload"

Vue.component('FileUpload', FileUpload)

import Router from 'vue-router'

const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyCApMQt0Bi4knERl3Eeyw-P7R0_sElotno',
    libraries: 'places',
    region: 'VI', // 这个地区自己定
    language: 'zh' // 这个语言自己定
  },
  installComponents: true
})

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')