import axios from 'axios'
import ElementUI from "element-ui";

import router from '../router'

axios.defaults.baseURL = process.env.VUE_APP_BASE_RUL
axios.defaults.timeout = 10000
axios.defaults.headers.post['Content-Type'] = 'application/json;charset=UTF-8'
axios.defaults.headers.put['Content-Type'] = 'application/json;charset=UTF-8'
// axios.defaults.headers.onlineId = '1583339349856444418'

// // 请求拦截器
axios.interceptors.request.use(
    config => {
        // 每次发送请求之前判断是否存在token，如果存在，则统一在http请求的header都加上token，不用每次请求都手动添加了
        // 即使本地存在token，也有可能token是过期的，所以在响应拦截器中要对返回状态进行判断
        const MemberAuthorization = localStorage.getItem('MemberAuthorization')
        const onlineId = localStorage.getItem('onlineId')
        config.headers.onlineId = onlineId

        MemberAuthorization && (config.headers.MemberAuthorization = MemberAuthorization) 
        return config
    },
    error => {
        return Promise.error(error)
    })

// 响应拦截器
axios.interceptors.response.use(
    response => {
        console.log('response')
        console.log(response)
        if (response.data.code == 200) {
            return Promise.resolve(response)
        } else if (response.data.code == 401) {
           
            let loginType = localStorage.getItem('loginType')
            if(loginType == 1){
                    router.replace({
                        path: '/userlogin',
                    })
            }else{
                    router.replace({
                        path: '/login',
                    })
            }
            
            return Promise.reject(response)
        } else {
            ElementUI.Message({
                message: response.data.msg,
                type: 'error',
                duration: 2000
            })
            return Promise.reject(response)
        }
    },
    // 服务器状态码不是200的情况
    error => {
        if (error.response.data.code) {
            switch (error.response.data.code) {
                // 401: 未登录
                // 未登录则跳转登录页面，并携带当前页面的路径
                // 在登录成功后返回当前页面，这一步需要在登录页操作。
                // case 401:
                //   router.replace({
                //     path: '/',
                //     query: { redirect: router.currentRoute.fullPath }
                //   })
                //   break
                // 403 token过期
                // 登录过期对用户进行提示
                // 清除本地token和清空vuex中token对象
                // 跳转登录页面
                // case 403:
                //   Toast({
                //     message: '登录过期，请重新登录',
                //     duration: 1000,
                //     forbidClick: true
                //   })
                //   // 清除token
                //   localStorage.removeItem('token')
                //   store.commit('loginSuccess', null)
                //   // 跳转登录页面，并将要浏览的页面fullPath传过去，登录成功后跳转需要访问的页面
                //   setTimeout(() => {
                //     router.replace({
                //       path: '/login',
                //       query: {
                //         redirect: router.currentRoute.fullPath
                //       }
                //     })
                //   }, 1000)
                //   break
                // 404请求不存在
                case 404:
                    ElementUI.Message({
                        title: '警告',
                        message: '网页未找到',
                        type: 'warning'
                    })
                    break
                    // 其他错误，直接抛出错误提示
                default:
                    ElementUI.Message({
                        title: '警告',
                        message: '接口访问错误,请联系管理员',
                        type: 'error'
                    })
            }

            return Promise.reject(error)
        }
    }
)

/**
 * get方法，对应get请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function get(url, params) {
    return new Promise((resolve, reject) => {
        axios.get(url, {
            params: params
        }).then(res => {
            resolve(res.data)
        }).catch(err => {
            reject(err.data)
        })
    })
}
/**
 * post方法，对应post请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function post(url, params) {
    return new Promise((resolve, reject) => {
        axios.post(url, params)
            .then(res => {
                resolve(res.data)
            })
            .catch(err => {
                reject(err.data)
            })
    })
}


/**
 * post方法，对应post请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function DELETE(url, params) {
    return new Promise((resolve, reject) => {
        axios.delete(url, params)
            .then(res => {
                resolve(res.data)
            })
            .catch(err => {
                reject(err.data)
            })
    })
}

export function PUT(url, params) {
    return new Promise((resolve, reject) => {
        axios.put(url, params)
            .then(res => {
                resolve(res.data)
            })
            .catch(err => {
                reject(err.data)
            })
    })
}