<template>
  <div>
    <div style="height:70px"></div>
    <div class="headertop">
     <span @click="toPage">沪公网安备11000002000001号</span>
     <span>沪ICP证030173号</span>
  </div>

  </div>
 
</template>

<script>
export default {
  name: "headertop",
  data() {
    return {
      input2: "",
    };
  },
  methods: {
    toPage() {
      window.location.href = 'http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11000002000001'
    },
  },
};
</script>

<style lang="scss" scoped>
.headertop {
  position: fixed;
    bottom: 0;
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    background: #1d1c1c;
    z-index: 1000000;
  span{
    display: block;
    color: #fff;
    margin-right: 40px;
  }
}

</style>
