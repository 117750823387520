import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    place: '',
    type: 2, //头部的分类
    name:'',
    logo:'',
    lat:'',
    lng:'',
  },
  mutations: {
    setPlace(state, key) {
      state.place = key;
    },
    setType(state, key) {
      state.type = key;
    },
    setStoreName(state, key) {
      state.name = key;
    },
    setLogi(state, key) {
      state.logo = key;
    },
    setlatAndlng(state, key) {
      state.lat = key.lat;
      state.lng = key.lng;
    },
  },
  actions: {},
  modules: {}
})