import {
    get,
    post,
    DELETE,
    PUT
} from './http'
import qs from 'qs'

//线上代码
export function loginbyname(data) {
    return post('/member/loginByPhone', JSON.stringify(data))
}
export function membersendCode(data) {
    return post('/member/sendCode', JSON.stringify(data))
}

export function neighborInfocomment(data) {
    return post('/member/neighborInfo/comment', JSON.stringify(data))
}

export function neighborInfolike(data) {
    return post('/member/neighborInfo/like', JSON.stringify(data))
}

export function neighborInfounLike(data) {
    return post('/member/neighborInfo/unLike', JSON.stringify(data))
}

export function memberregisterOne(data) {
    return post('/member/registerOne', JSON.stringify(data))
}

export function memberresetPwd(data) {
    return post('/member/memberUser/resetPwd', JSON.stringify(data))
}

export function memberUserAddresslist(data) {
    return get('/member/memberUserAddress/list', data)
}
export function commonlocation(data) {
    return get('/common/location', data)
}

export function commonipInfo(data) {
    return get('/common/ipInfo', data)
}

export function commonasia(data) {
    return get('/common/asia', data)
}


export function countryPrefixall(data) {
    return get('/member/countryPrefix/all ', data)
}

export function codeImg(data) {
    return get('/member/codeImg', data)
}


export function orderdetail(data) {
    return get('/member/order/detail', data)
}
export function cartgetSubscript(data) {
    return get('/member/cart/getSubscript', data)
}

export function membermemberUserAddress(data) {
    return post('/member/memberUserAddress', JSON.stringify(data))
}
export function cartupdate(data) {
    return post('/member/cart/update', JSON.stringify(data))
}
export function ordercreateCartOrder(data) {
    return post('/member/order/createCartOrder', JSON.stringify(data))
}
export function cartNextSet(data) {
    return post('/member/cart/cartNextSet', JSON.stringify(data))
}



export function membermemberUserAddressdel(data) {
    return DELETE(`/member/memberUserAddress/${data}`)
}

export function membermemberUserAddressInfo(data) {
    return get(`/member/memberUserAddress/${data}`)
}

export function membermemberUserAddressEdit(data) {
    return PUT(`/member/memberUserAddress`, JSON.stringify(data))
}

export function getmemberUserinfo() {
    return get(`/member/memberUser/info`)
}

export function memberUserupdateInfo(data) {
    return post('/member/memberUser/updateInfo', JSON.stringify(data))
}

export function customerServiceget() {
    return get(`/member/customerService/get`)
}

export function goodClassifylist() {
    return get(`/member/goodClassify/list`)
}
export function onlineget() {
    return get(`/member/online/get`)
}

export function goodlist(data) {
    return get(`/member/good/list`, data)
}

export function goodcollect(data) {
    return post('/member/good/collect', JSON.stringify(data))
}

export function goodunCollect(data) {
    return post('/member/good/unCollect', JSON.stringify(data))
}


export function businessdelete(data) {
    return get('/member/business/delete', data)
}


export function gooddetail(data) {
    return post('/member/good/detail', JSON.stringify(data))
}

export function cartadd(data) {
    return post('/member/cart/add', JSON.stringify(data))
}

export function cartget(data) {
    return get(`/member/cart/get`, data)
}

export function cartdelete(data) {
    return get(`/member/cart/delete/${data}`)
}

export function cartcartNextSet(data) {
    return post('/member/cart/cartNextSet', JSON.stringify(data))
}

export function cartcartNextGet() {
    return get(`/member/cart/cartNextGet`)
}

export function ordercreateOrder(data) {
    return post('/member/order/createOrder', JSON.stringify(data))
}

export function orderpaySuccessOrder(data) {
    return post('/member/order/paySuccessOrder', JSON.stringify(data))
}

export function orderlist(data) {
    return get(`/member/order/list`,data)
}

export function classifybuyList(data) {
    return get(`/member/classify/infoList`,data)
}

export function classifyneighborList(data) {
    return get(`/member/classify/neighborList`,data)
}

export function classifyinfoList(data) {
    return get(`/member/classify/infoList`,data)
}
export function classifyinfoAuthList(data) {
    return get(`/member/classify/infoAuthList`,data)
}

export function cartclear(data) {
    return post('/member/cart/clear', JSON.stringify(data))
}

export function neighborInfolist(data) {
    return get(`/member/neighborInfo/list`,data)
}


export function neighborInfocollect(data) {
    return post('/member/neighborInfo/collect', JSON.stringify(data))
}


export function neighborInfounCollect(data) {
    return post('/member/neighborInfo/unCollect', JSON.stringify(data))
}

export function neighborShoplist(data) {
    return get(`/member/neighborShop/list`,data)
}

export function inwaySetall(data) {
    return get(`/member/inwaySet/all`,data)
}



export function neighborServelist(data) {
    return get(`/member/neighborServe/list`,data)
}

export function neighborShopdetail(data) {
    return post(`/member/neighborShop/detail`,data)
}


export function neighborShopcollect(data) {
    return post('/member/neighborShop/collect', JSON.stringify(data))
}

export function neighborShopunCollect(data) {
    return post('/member/neighborShop/unCollect', JSON.stringify(data))
}

export function neighborServecollect(data) {
    return post('/member/neighborServe/collect', JSON.stringify(data))
}

export function neighborServeunCollect(data) {
    return post('/member/neighborServe/unCollect', JSON.stringify(data))
}

export function neighborServedetail(data) {
    return post('/member/neighborServe/detail', JSON.stringify(data))
}

export function neighborInfodetail(data) {
    return post('/member/neighborInfo/detail', JSON.stringify(data))
}

export function businesslist(data) {
    return get(`/member/business/list`,data)
}

export function countryAreatree(data) {
    return get(`/member/countryArea/tree`,data)
}


export function contactInfoall(data) {
    return get(`/member/contactInfo/all`,data)
}

export function neighborInfoadd(data) {
    return post('/member/neighborInfo/add', JSON.stringify(data))
}

export function neighborInfouserList(data) {
    return get(`/member/neighborInfo/userList`,data)
}

export function neighborInfodelete(data) {
    return post('/member/neighborInfo/delete', JSON.stringify(data))
}
export function neighborInfoupdate(data) {
    return post('/member/neighborInfo/update', JSON.stringify(data))
}

export function memberUserinfo(data) {
    return get(`/member/memberUser/info`,data)
}

export function memberCollectAddresslist(data) {
    return get(`/member/memberCollectAddress/list`,data)
}
export function complaintadd(data) {
    return post(`/member/complaint/add`,JSON.stringify(data))
}
export function complaintuserList(data) {
    return get(`/member/complaint/userList`,data)
}

export function complaintback(data) {
    return post('/member/complaint/back', JSON.stringify(data))
}
export function complaintappeal(data) {
    return post('/member/complaint/appeal', JSON.stringify(data))
}

export function memberCollectAddressadd(data) {
    return post('/member/memberCollectAddress/add', JSON.stringify(data))
}

export function membermemberCollectAddressdel(data) {
    return DELETE(`/member/memberCollectAddress/${data}`)
}

export function memberCollectAddressupdate(data) {
    return post('/member/memberCollectAddress/update', JSON.stringify(data))
}

export function onlineconfig(data) {
    return get(`/member/online/config `,data)
}
