import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)


const routes = [
  {
    path: '/',
    redirect: '/information',
  },{
    path: '/home',
    name: 'home',
    component: () => import('../views/home.vue')
  },
  {
    path: '/goodsInfo',
    name: 'goodsInfo',
    component: () => import('../views/goodsInfo.vue')
  },
  {
    path: '/collect',
    name: 'collect',
    component: () => import('../views/collect.vue')
  },
  {
    path: '/information',
    name: 'information',
    component: () => import('../views/information/information.vue')
  },
  {
    path: '/informationsc',
    name: 'informationsc',
    component: () => import('../views/information/informationsc.vue')
  },
  {
    path: '/my',
    name: 'my',
    component: () => import('../views/my.vue')
  },
  {
    path: '/infomationInfo3',
    name: 'infomationInfo',
    component: () => import('../views/information/infomationInfo.vue')
  },
  {
    path: '/pay',
    name: 'pay',
    component: () => import('../views/pay.vue')
  },
  {
    path: '/informationmy',
    name: 'informationmy',
    component: () => import('../views/information/informationmy.vue')
  },
  {
    path: '/checkaddress',
    name: 'checkaddress',
    component: () => import('../views/information/checkaddress.vue')
  },
  {
    path: '/checkaddresstx',
    name: 'checkaddresstx',
    component: () => import('../views/information/checkaddresstx.vue')
  },
  {
    path: '/userlogin',
    name: 'login',
    component: () => import('../views/login.vue')
  },
  {
    path: '/login',
    name: 'login2',
    component: () => import('../views/login2.vue')
  },
  {
    path: '/car',
    name: 'car',
    component: () => import('../views/car.vue')
  },
  {
    path: '/collection',
    name: 'collection',
    component: () => import('../views/collection.vue')
  },
]

var routermode = 'history'
if (process.env.NODE_ENV === 'production') {
  routermode = 'hash'
}

const router = new VueRouter({
  // mode: 'history',
  //上线换成 hash
  mode: routermode,
  base: process.env.BASE_URL,
  routes
})

export default router