<template>
  <div id="app">
    <!-- <headertop v-show="infomation"></headertop> -->
    <router-view />
  </div>
</template>

<script>
// import headertop from "@/views/information/components/header.vue";
import { inwaySetall } from "@/request/api.js";
import axios from 'axios'
export default {
  components: {
    // headertop,
  },
  data() {
    return {
      infomation: false,
    };
  },
  watch: {
    $route(to, from) {
      if(to.path == '/checkaddress' || to.path == '/infomationInfo' || to.path == '/informationsc' || to.path == '/informationmy'||to.path == '/information'||to.path == '/infomationInfo2'||to.path == '/infomationInfo3'){
        this.infomation = true
      }else{
        this.infomation = false
      }
    },
  },
  created() {
    inwaySetall().then((res) => {
        console.log(res);
        document.title = res.data.webTitle;
        localStorage.setItem('footer',res.data.footer)
        let $favicon = document.querySelector('link[rel="icon"]');
        if ($favicon !== null) {
          $favicon.href = res.data.webFavicon;
        } else {
          $favicon = document.createElement("link");
          $favicon.rel = "icon";
          $favicon.href = res.data.webFavicon;
          document.head.appendChild($favicon);
        }
      });


  },
  methods: {
    getVersion() {
      axios.get("/version.json", {
        params: {
          date: new Date()
        }
      }).then(res => {

        let version = res.data.version;
        let localVersion = localStorage.version

        if (localVersion != version) {
          setTimeout(() => {
            window.top.location.reload();
          }, 3000)
          localStorage.setItem("version", version)
        }

      })
    }
  },
  beforeDestroy() {},
};
</script>
<style>
p {
  margin: 0;
  padding: 0;
}
.modal_popper.el-popover{
}
.w-e-text-container{
  z-index: 1!important;
}
.w-e-toolbar{
  z-index: 11!important;

}

/*里面的代码可以根据自己需求去进行更改*/
/* 设置滚动条的样式 */
::-webkit-scrollbar {
width:0px;
}
/* 滚动槽 */
::-webkit-scrollbar-track {
-webkit-box-shadow:inset006pxrgba(0,0,0,0.3);
border-radius:10px;
}
/* 滚动条滑块 */
::-webkit-scrollbar-thumb {
border-radius:10px;
background:rgba(0,0,0,0.1);
-webkit-box-shadow:inset006pxrgba(0,0,0,0.5);
}
::-webkit-scrollbar-thumb:window-inactive {
background:rgba(0,0,0,0.4);
}


.hidePopper{
  display: none;
}
.el-popover{
  z-index: 100002!important;
}

html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  font-size: 16px;
}

h1,
h2,
h3,
h4,
h5 {
  margin: 0;
}
ul,
li {
  list-style: none;
}
img {
  border: 0px;
}
.lastBox{
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  background: #000;
  z-index: 1000000;
}
</style>
